<template>
    <div id="newGatewayPage" class="baseTemplate">
        <!-- begin:: mobile menu -->
        <app-mobilemenu></app-mobilemenu>
        <!-- end:: mobile menu -->

        <div class="kt-grid kt-grid--hor kt-grid--root">
            <div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page">
                <!-- begin:: home menu -->
                <app-siteconfigurationmenu activeItem="editSiteUser"></app-siteconfigurationmenu>
                <!-- end:: home menu -->

                <div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper">
                    <!-- begin:: header -->
                    <app-header></app-header>
                    <!-- end:: header -->

                    <div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
                        <!-- begin:: Content Head -->
                        <div class="kt-subheader kt-grid__item">
                            <app-sitetitle subHeaderDescKey="siteusers_editUserRole"></app-sitetitle>
                        </div>
                        <!-- end:: Content Head -->

                        <!-- begin:: Content -->
                        <div class="kt-content kt-grid__item kt-grid__item--fluid">
                            <!-- begin:: Content -->

                            <div class="row">
                                <div class="col-md-12">
                                    <div class="kt-portlet">
                                        <div class="kt-portlet__head">
                                            <div class="kt-portlet__head-label">
                                                <span class="kt-portlet__head-icon">
                                                    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" class="kt-svg-icon">
                                                        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                            <polygon id="Shape" points="0 0 24 0 24 24 0 24" />
                                                            <path d="M12,11 C9.790861,11 8,9.209139 8,7 C8,4.790861 9.790861,3 12,3 C14.209139,3 16,4.790861 16,7 C16,9.209139 14.209139,11 12,11 Z" id="Mask" fill="#000000" fill-rule="nonzero" opacity="0.3" />
                                                            <path d="M3.00065168,20.1992055 C3.38825852,15.4265159 7.26191235,13 11.9833413,13 C16.7712164,13 20.7048837,15.2931929 20.9979143,20.2 C21.0095879,20.3954741 20.9979143,21 20.2466999,21 C16.541124,21 11.0347247,21 3.72750223,21 C3.47671215,21 2.97953825,20.45918 3.00065168,20.1992055 Z" id="Mask-Copy" fill="#000000" fill-rule="nonzero" />
                                                        </g>
                                                    </svg>
                                                </span>
                                                <h3 class="kt-portlet__head-title">
                                                    {{ $t("siteusers_editUserRole") }}
                                                </h3>
                                            </div>
                                        </div>

                                        <!--begin::Form-->
                                        <form class="kt-form" novalidate="novalidate">
                                            <div class="kt-portlet__body">
                                                <div class="row">
                                                    <div class="col-md-12">
                                                        <div class="form-group">
                                                            <label for="newGatewayForm_nameInput">{{ $t("siteusers_nameLabel") }} *</label>
                                                            <div class="input-group">
                                                                <div class="input-group-prepend">
                                                                    <span class="input-group-text"><i class="la la-pencil"></i></span>
                                                                </div>
                                                                <input v-model="vName" type="text" class="form-control" id="editSiteUserForm_nameInput" disabled />
                                                            </div>
                                                        </div>

                                                        <div class="form-group">
                                                            <label class="marginB20">{{ $t("siteusers_assignNewRoleText") }}</label>
                                                            <div class="kt-radio-list">
                                                                <label v-for="role in vRolesList" :key="role.id" class="kt-radio kt-radio--bold kt-radio--brand">
                                                                    <input type="radio" :id="'roleRadio_' + role.name" name="role" :value="role.id" v-model="vRoleId" @change="onChangeForm" checked="checked" /> {{ role.isDefault === true ? $t("ROLE_" + role.name) : role.name }} <br />
                                                                    <div class="featuresList">
                                                                        (
                                                                        <span v-for="(feature, index) in role.features" :key="feature.id">{{ $t("ROLE_FEATURES_" + feature.name) }}<span v-if="index + 1 < role.features.length">, </span></span>
                                                                        )
                                                                    </div>
                                                                    <span></span>
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="kt-portlet__foot">
                                                <div class="kt-form__actions kt-form__actions--right">
                                                    <button id="editSiteUserForm_updateUserRoleButton" @click="onUpdateUserRoleButton" type="button" class="btn btn-brand kt-margin-r-5" :disabled="!isFormDataChanged">
                                                        {{ $t("common_update") }}
                                                    </button>
                                                    <button id="editSiteUserForm_cancelButton" @click="onCancelButton" type="button" class="btn btn-secondary">{{ $t("common_cancel") }}</button>
                                                </div>
                                            </div>
                                        </form>
                                        <!--end::Form-->
                                    </div>
                                </div>
                            </div>

                            <!-- end:: Content -->
                        </div>
                        <!-- end:: Content -->
                    </div>

                    <!-- begin:: Footer -->
                    <app-footer></app-footer>
                    <!-- end:: Footer -->
                    <!-- begin:: Modal to confirm leave page -->
                    <app-confirmleavepagemodal ref="confirmleavepagemodalref"></app-confirmleavepagemodal>
                    <!-- end::Modal -->
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import SiteTitle from "../site/widgets/sitetitle.vue";
import commonVueHelper from "../../helpers/commonVueHelper";

export default {
    data() {
        return {
            siteId: this.$route.params.siteId,
            userId: this.$route.params.userId,
            vName: "",
            vRoleId: "",
            vRolesList: [],
            isFormDataChanged: false
        };
    },
    created: function() {
        console.log("Component(editSiteUser)::created() - called");
        this.getSiteRoles(this.siteId);
    },
    mounted: function() {
        console.log("Component(editSiteUser)::mounted() - Init metronic layout");
        KTLayout.init();
    },
    destroyed: function() {
        console.log("Component(editSiteUser)::destroyed() - called");
        this.resetSiteRolesState();
    },
    validations: {
        // -- validations -- List of controls to apply to validate a form.
        // --
    },
    watch: {
        // -- Watch -- Property to perform asynchronous or expensive operations in response to changing component "data".
        // --
        siteRoles() {
            if (this.siteRoles) {
                this.vRolesList = _.orderBy(this.siteRoles, [role => role.name.toLowerCase()], ["asc"]);
                // Then, load site user role for a given user
                let jsonData = { siteId: this.siteId, userId: this.userId };
                this.getSiteUserById(jsonData);
            }
        },

        currentSiteUser() {
            if (this.currentSiteUser) {
                this.vName = this.currentSiteUser.user.firstName + " " + this.currentSiteUser.user.lastName;
                this.vRoleId = this.currentSiteUser.role.id;
            }
        }
    },
    computed: {
        // -- Computed -- Property will only re-evaluate when some of its reactive dependencies have changed.
        // To compose new data from existing datasource
        // To reference a value from the template (cached value)
        // --
        ...mapGetters(["currentSiteUser", "siteRoles"])
    },
    methods: {
        // -- Methods -- Property to perform an action.
        // To react on some event happening in the DOM (called every time an event occurs even if method returns the same result)
        // To call a function when something happens in your component (from property "computed" or "watch")
        // ---
        ...mapActions(["getSiteRoles", "updateUserOfSiteById", "getSiteUserById", "resetSiteRolesState"]),

        // Function called when user click on the "Cancel" button
        onCancelButton() {
            console.log("Component(editSiteUser)::onCancelButton() - called");
            this.$router.push({ name: "users", params: { fromAction: "cancelButton" } });
        },

        onUpdateUserRoleButton() {
            console.log("Component(editSiteUser)::onUpdateUserRoleButton() - called");
            const data = {
                userId: this.userId,
                roleId: this.vRoleId,
                siteId: this.siteId
            };
            this.updateUserOfSiteById(data);
        },

        onChangeForm() {
            this.isFormDataChanged = true;
        }
    },
    components: {
        // -- Components -- List of local components used in the current template
        // --
        "app-sitetitle": SiteTitle
    },
    beforeRouteLeave(to, from, next) {
        // -- Called when the route that renders this component is about to be navigated away from.
        commonVueHelper.displayConfirmModalOnLeavePage(this, "editSiteUser", "update", this.isFormDataChanged, to, from, next);
    }
};
</script>

<style scoped>
.baseTemplate {
    display: contents;
}
.featuresList {
    font-size: 12px;
    color: black;
}
</style>
